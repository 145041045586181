import React, { createElement } from 'react'
import Helmet from 'react-helmet'
import browserLang from 'browser-lang'
import { IntlProvider } from 'react-intl'
import {
  initialiseSegment,
  OneTrustConsent,
  GTMContainer,
} from 'cr-static-shared-components'

import favicon from '@images/favicon.png'
import { IntlContextProvider, languageList } from '@intl'
import Metadata from '@components/seo/metadata'
import { useSiteMetadata } from '@hooks/use-site-metadata'

const preferDefault = (m) => (m && m.default) || m

const withIntlProvider = (intl) => (children) => (
  <IntlProvider
    locale={intl.language}
    defaultLocale={intl.defaultLanguage}
    messages={intl.messages}
  >
    <IntlContextProvider value={intl}>{children}</IntlContextProvider>
  </IntlProvider>
)

const wrapPage = ({ element, props }) => {
  if (!props) return

  const {
    pageContext: { intl },
    location: { pathname, hash, search },
  } = props

  const { language, languages, redirect, routed, originalPath, pathPrefix } =
    intl

  let detected

  if (typeof window !== 'undefined') {
    detected =
      window.localStorage.getItem('gatsby-intl-language') ||
      browserLang({
        languages,
        fallback: language,
      })

    if (!languages.includes(detected)) {
      detected = language
    }

    window.___gatsbyIntl = intl
  }

  const isRedirect = redirect && !routed && detected !== 'en'

  if (isRedirect) {
    if (typeof window !== 'undefined') {
      const newUrl = `/${detected}${pathPrefix}${originalPath}${hash}${search}`

      window.localStorage.setItem('gatsby-intl-language', detected)
      window.location.replace(newUrl)
    }
  }

  const renderElement = isRedirect
    ? // eslint-disable-next-line
      GATSBY_INTL_REDIRECT_COMPONENT_PATH &&
      // eslint-disable-next-line
      createElement(preferDefault(require(GATSBY_INTL_REDIRECT_COMPONENT_PATH)))
    : element

  const PageComponent = () => {
    const {
      assetPrefix,
      siteMetadata: { baseUrl: url },
    } = useSiteMetadata()

    const path = pathname.replace(assetPrefix, '')
    let canonicalHref = `${url}${pathname}`

    const langList = !Array.isArray(languageList)
      ? Object.keys(languageList)
      : languageList
    const localized = langList.find((lang) => path.includes(`/${lang}/`))
    const isDefaultPage = localized ? false : true
    if (isDefaultPage) canonicalHref = `${url}${assetPrefix}${path}`

    return (
      <>
        <OneTrustConsent />

        {typeof window !== 'undefined' && initialiseSegment()}

        <Helmet>
          <link rel="icon" href={favicon} />
          <link rel="canonical" href={canonicalHref} />
          <meta property="og:url" content={canonicalHref} />
        </Helmet>
        <GTMContainer />
        {isDefaultPage && <Metadata />}
        {renderElement}
      </>
    )
  }

  return withIntlProvider(intl)(<PageComponent />)
}

export default wrapPage
