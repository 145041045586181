const languageList = require('./languageList')
const gatsbyConfig = require('../../gatsby-config')

const pathPrefix = gatsbyConfig.assetPrefix
const languages = !Array.isArray(languageList)
  ? Object.keys(languageList)
  : languageList

module.exports = {
  path: `languages`,
  languages,
  defaultLanguage: `en`,
  redirect: true,
  flatten: false,
  pathPrefix,
}
