import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          assetPrefix
          siteMetadata {
            url
            baseUrl
            title
            description
            banner {
              url
              width
              height
            }
          }
        }
      }
    `
  )
  return site
}
