module.exports = {
  assetPrefix: `/events`,
  trailingSlash: `ignore`,
  siteMetadata: {
    baseUrl: `https://crunchyroll.com`,
    url: `https://crunchyroll.com/events`,
    title: `Events - Crunchyroll`,
    description: `Crunchyroll’s mission is to celebrate anime with fans around the world!`,
    banner: {
      url: `https://crunchyroll.com/events/default-banner.jpg`,
      width: `1080`,
      height: `565`,
    },
  },
  plugins: [
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-plugin-emotion`,
    `gatsby-plugin-react-helmet`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        defaults: {
          formats: [`auto`, `webp`],
          quality: 80,
        },
      },
    },
    `gatsby-plugin-image`,
  ],
}
