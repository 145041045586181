/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapPage from '@wrappers/wrap-page'
import wrapRoot from '@wrappers/wrap-root'

export const wrapPageElement = wrapPage
export const wrapRootElement = wrapRoot

window.page = window.page || {}
window.page.path = window.location.pathname

const scrollToEl = (hash, behavior) => {
  const hashOnly = hash.split('?')[0]
  const el = document.querySelector(hashOnly)
  if (!el) return

  const headerHeight = document.querySelector('.header-placeholder')
    .clientHeight
  const offsetTop = el.offsetTop - headerHeight

  if (behavior === 'smooth') {
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    })
  } else {
    window.scrollTo(0, offsetTop)
  }
}

export const shouldUpdateScroll = ({
  routerProps: {
    location: { hash },
  },
}) => {
  if (hash) {
    scrollToEl(hash, 'smooth')
    return false
  } else {
    window.scrollTo(0, 0)
    return false
  }
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    scrollToEl(hash)
  }
}
